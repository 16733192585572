

const SimpleCard = ({i, d, checkout, release}) => {


    return (
        <>
        <div className='item-wrap' key={ i }>
            <div className={`left item-img${i}`}></div>
          
                
                <div className='right'>
                <h4>{d.Title}</h4>
                        <p className='sub'>£{d.Cost + '. ' + d.Date}</p>
                        <p className='blurb'>{d.Blurb}</p>
                </div>
                {!release &&
                <button className='book btnright' onClick={()=>checkout(d)}>Book now</button>
                }

  
        </div>
        </>
    )
}

export default SimpleCard