import SimpleCard from '../components/SimpleCard'
import seasonData from '../api/data.js?v=131'
import { MdOutlineClose } from 'react-icons/md';

import { useState, useEffect } from 'react'

const Reverse = () => {

    let deadline = new Date('2024-09-13');
    let today = new Date();

    let url = window.location;
    let params = new URLSearchParams(url.search);
    let email = params.get('e');

    const [item, setItem] = useState([]);
    const [load, setLoad] = useState(false);
    const [user, setUser] = useState([]);
    const [isReleased, setIsReleased] = useState(false);



  useEffect(()=> {  

    if (today > deadline)
    {
      setLoad(false);
    }

    else if (email)
      {
        fetch('/sm1/reserve-check.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              Email:email}),
      })
      .then((res) => res.json())
      .then((data) => {
        setUser(data);
        setIsReleased(data[0].Skip);
        setItem(seasonData[data[0].Session])
        setLoad(true);
      })
      .catch((error) => {
          console.log(error);
      });
     }
     

    },[]);

    const releaseClick = (id) => {
      console.log(id);
      fetch('/sm1/reserve-check.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: id, isReleased: 1 }),
      })
        .then((res) => res.json())
        .then((data) => {
          setIsReleased(data.success);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getCheckout = async (session) => {
      const url = '/sm1/create-checkout-session.php';    
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify([session]) // Send the session data as JSON
        });
    
        if (response) {
          const responseData = await response.text();
          window.location.href = responseData; 
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
 

    return (
        <>
        { load ?
         <>
         <div className='banner'></div>
         <section className='line' style={{'margin':'0 auto 50px auto'}}>
           <div className='intro'>
            <h2>Hello {user[0].Name}!</h2>
             <p className='opening'>We've reserved a place for you this season!</p>
             <p>Our autumn season is an invitation to look up and around. We think you'd really like our workshop '{item.Title}' on {item.Date}. It costs £{item.Cost}.</p>
              <p>If you don't want this place, please release it and we'll offer it to someone else. Otherwise, book and join us this season.</p>
             
             { !isReleased ?
             <div className='sidebyside'>
             <button className='book' onClick={()=>getCheckout(item)}>Book now</button>
             
             <button className='book waiting' onClick={()=>releaseClick(user[0].ID)}>
             <MdOutlineClose size={30} style={{color:'#51516b', position:'absolute', top: '10px', left:'18px'}} />Release</button>
             </div>
             :

             <div className='sidebyside'>
                         
             <button className='book released'>
             <MdOutlineClose size={30} style={{color:'#fff', position:'absolute', top: '10px', left:'18px'}} />Released</button>
             </div>
            }
             <p style={{'fontSize':'1.1em'}}>Please note that your place will automatically be released on Friday 13 September.</p>
            </div>
         </section>
          <section className='main'>
 
                <SimpleCard d={item} key={item.ID} i={item.ID} checkout={getCheckout} release={isReleased} />
            
         </section>
         </>
         :
         <>
         <div className='banner'></div>
         <section className='line' style={{'margin':'0 auto 50px auto'}}>
         <div className='intro'>
          <h2>Welcome</h2>
           <p className='opening'>This offer seems to have expired.</p>
           <p>Why not check out our latest season <a href='https://booking.write-and-shine.com/' className='link'>here</a>.</p>
           </div>
           </section>
           </>
         }
       </>
    )
}

export default Reverse